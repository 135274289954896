import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { fetchContributorById } from '../../utils/api/clients';

export const ContributorDetails = () => {
  let { id } = useParams();

  const { data, isLoading, error, isError } = useQuery(
    ['contributorData', id],
    fetchContributorById(id)
  );

  if (isLoading) {
    return <div className="overflow-hidden"></div>;
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className={'overflow-hidden'}>
      <div className="md:flex  md:items-center md:justify-between max-w-6xl mx-auto my-6 px-4 sm:px-6 lg:px-8">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Contributor Details
          </h2>
        </div>
      </div>
      <div className="flex flex-col max-w-5xl mx-auto bg-gray-100 ">
        <div>
          <div className="pb-16">
            <div className={'flex justify-start'}></div>
            <div className={'px-6 max-w-lg'}>
              <dl className="mt-2 divide-y divide-gray-200 border-2 border-gray-400 rounded-xl">
                <div className=" py-4 px-4 aspect-w-10 aspect-h-7 block overflow-hidden rounded-lg">
                  <a hred={data.html_url}>
                    <img
                      src={data.avatar_url}
                      alt={`${data.login}'s picture`}
                      className="object-cover rounded-full w-52"
                    />
                  </a>
                </div>
                <h3 className="font-medium text-gray-900 px-4">Information</h3>
                <div className="flex justify-between py-3 text-sm font-medium bg-white px-4">
                  <dt className="text-gray-500">Login: </dt>
                  <dd className="whitespace-nowrap text-gray-900">
                    <a href={data.html_url}>{data.login}</a>
                  </dd>
                </div>
                <div className="flex justify-between py-3 text-sm font-medium bg-white px-4 rounded-lg border-t-0 border-r-0 border-l-0">
                  <dt className="text-gray-500">Total contributions: </dt>
                  <dd className="whitespace-nowrap text-gray-900">
                    {data.contributions_count}
                  </dd>
                </div>
                {data.repository_contributions.length > 0 ? (
                  <div
                    className={
                      'justify-between py-3 text-sm font-medium bg-white border-gray-400 rounded-lg border-t-0 border-r-0 border-l-0'
                    }
                  >
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="py-1.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-600 sm:pl-6"
                          >
                            Repository
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-1.5 text-left text-sm font-semibold text-gray-600"
                          >
                            User Type
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-1.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-600"
                          >
                            Contributions
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {data.repository_contributions.map((contribution) => (
                          <tr key={contribution.repository}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {contribution.repository}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {contribution.user_type}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-sm text-gray-500">
                              {contribution.contributions}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div
                    className={
                      'flex justify-between py-3 text-sm font-medium bg-white px-4 border-gray-400 rounded-lg border-t-0 border-r-0 border-l-0'
                    }
                  >
                    <dt className="text-gray-500">
                      Repository contributions:{' '}
                    </dt>
                    <dd className="whitespace-nowrap text-gray-900">0</dd>
                  </div>
                )}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
