import React from 'react';
import { CheckCircleIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { fetchApplications } from '../utils/api/clients';
import { formatDateMMDDYYYY } from '../utils';

export const Applications = () => {
  const { data, isLoading, status, error } = useQuery(
    ['appData'],
    fetchApplications
  );

  if (status === 'loading') {
    return (
      <div className="overflow-hidden">
        {/* Subheader */}
        <div className="md:flex  md:items-center md:justify-between max-w-6xl mx-auto my-6 px-4 sm:px-6 lg:px-8">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              Applications
            </h2>
          </div>
          <div className="mt-4 flex md:mt-0 md:ml-4">
            <Link to="/analyze">
              <button
                type="button"
                className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                New application
              </button>
            </Link>
          </div>
        </div>

        <div className="flex flex-col max-w-5xl mx-auto bg-gray-100 ">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 bg-gray-100">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Last scan
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Dependencies
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Up-to-date
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    <tr>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        <div className="w-36 h-6 bg-gray-300 animate-pulse rounded-sm "></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="w-36 h-6 bg-gray-300 animate-pulse rounded-sm "></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="w-20 h-6 bg-gray-300  animate-pulse rounded-sm "></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="w-6 h-6 bg-gray-300  animate-pulse rounded-sm "></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <div className="w-36 h-6 bg-gray-300  animate-pulse rounded-sm "></div>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        <div className="w-36 h-6 bg-gray-300 animate-pulse rounded-sm "></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="w-36 h-6 bg-gray-300 animate-pulse rounded-sm "></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="w-20 h-6 bg-gray-300  animate-pulse rounded-sm "></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="w-6 h-6 bg-gray-300  animate-pulse rounded-sm "></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <div className="w-36 h-6 bg-gray-300  animate-pulse rounded-sm "></div>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        <div className="w-36 h-6 bg-gray-300 animate-pulse rounded-sm "></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="w-36 h-6 bg-gray-300 animate-pulse rounded-sm "></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="w-20 h-6 bg-gray-300  animate-pulse rounded-sm "></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="w-6 h-6 bg-gray-300  animate-pulse rounded-sm "></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <div className="w-36 h-6 bg-gray-300  animate-pulse rounded-sm "></div>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        <div className="w-36 h-6 bg-gray-300 animate-pulse rounded-sm "></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="w-36 h-6 bg-gray-300 animate-pulse rounded-sm "></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="w-20 h-6 bg-gray-300  animate-pulse rounded-sm "></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="w-6 h-6 bg-gray-300  animate-pulse rounded-sm "></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <div className="w-36 h-6 bg-gray-300  animate-pulse rounded-sm "></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (status === 'error') {
    return <span>Error: {error.message}</span>;
  }

  if (!data) {
    return (
      <div className="overflow-hidden">
        {/* Subheader */}
        <div className="md:flex  md:items-center md:justify-between max-w-6xl mx-auto my-6 px-4 sm:px-6 lg:px-8">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              Applications
            </h2>
          </div>
        </div>

        <div className="flex flex-col max-w-5xl mx-auto bg-gray-100 ">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 bg-gray-100">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b bg-white border-gray-200 sm:rounded-lg">
                <div className="sm:col-span-2 my-4 mx-4">
                  <div className="text-sm font-medium text-gray-500">
                    <Link to="/analyze">
                      <button
                        type="button"
                        className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
                          />
                        </svg>
                        <span className="mt-2 block text-sm font-medium text-gray-900">
                          Add a new application
                        </span>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="overflow-hidden">
      {/* Subheader */}
      <div className="md:flex  md:items-center md:justify-between max-w-6xl mx-auto my-6 px-4 sm:px-6 lg:px-8">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Applications
          </h2>
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <Link to="/analyze">
            <button
              type="button"
              className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              New application
            </button>
          </Link>
        </div>
      </div>

      <div className="flex flex-col max-w-5xl mx-auto bg-gray-100 ">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 bg-gray-100">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Last scan
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Dependencies
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Up-to-date
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {data?.map((application, i) => (
                    <tr key={i}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        <Link to={`application/${application.id}`}>
                          {application.name}
                        </Link>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {formatDateMMDDYYYY(application.last_scanned)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {application.full_dependency_count}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {`application.up_to_date?` ? (
                          <CheckCircleIcon
                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400 "
                            aria-hidden="true"
                          />
                        ) : (
                          <CheckCircleIcon
                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400 "
                            aria-hidden="true"
                          />
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <Link to={`/application/${application.id}`}>
                          <ChevronRightIcon
                            className="h-7 w-7 whitespace-nowrap text-sm text-black-500"
                            aria-hidden="true"
                          />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
