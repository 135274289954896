import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

export const PaginationButtons = ({ pagination, disableNextPage }) => {
  return (
    pagination && (
      <div className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
        <div className={'flex justify-end'}>
          <span className={'whitespace-nowrap py-2'}>
            Page: {pagination.isFetching ? '?' : pagination.page}
          </span>
          <button
            onClick={() => {
              if (pagination.page <= 1) return;
              pagination.setPage((page) => page - 1);
            }}
          >
            <ChevronLeftIcon
              className={`h-7 w-7 whitespace-nowrap text-sm ${
                pagination.page <= '1' ? 'text-black-500' : 'text-indigo-900'
              } inline`}
              aria-hidden="true"
            />
          </button>
          <button
            disabled={disableNextPage}
            onClick={() => {
              pagination.setPage((page) => parseInt(page, 10) + 1);
            }}
          >
            <ChevronRightIcon
              className={`h-7 w-7 whitespace-nowrap text-sm text-indigo-900 inline ${
                disableNextPage ? 'text-black-500' : 'text-indigo-900'
              }`}
              aria-hidden="true"
            />
          </button>
        </div>
      </div>
    )
  );
};
