import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import Loading from '../components/Loading';

const PrivateRoute = ({ children }) => {
  const { currentUser } = useContext(UserContext);

  if (currentUser === undefined) {
    return <Loading />;
  }

  return currentUser !== null ? children : <Navigate to="/" />;
};

export default PrivateRoute;
