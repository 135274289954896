import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchAdvisories } from '../utils/api/clients';
import { CustomTable } from '../components/table/CustomTable';
import { useSearchParams } from 'react-router-dom';

const headers = new Map([
  ['Package Name', 'package_name'],
  ['Summary', 'summary'],
  ['Severity', 'severity'],
  ['GitHub ID', 'github_id']
]);

export const Advisories = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = React.useState(
    isNaN(parseInt(searchParams.get('page')))
      ? '1'
      : searchParams.get('page') < 1
      ? '1'
      : searchParams.get('page')
  );

  useEffect(() => {
    if (page) {
      setSearchParams({ page });
    }
  }, [page]);

  const { data, isLoading, isFetching, error } = useQuery(
    ['advisories', page],
    fetchAdvisories(page)
  );

  if (error) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <div className="overflow-hidden">
      <CustomTable
        isLoading={isLoading}
        h={headers}
        data={data}
        endLink={`/advisory/<id>`}
        title={'Advisories'}
        pagination={{ isFetching, page, setPage }}
      />
    </div>
  );
};
