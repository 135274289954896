import { useState, createElement } from 'react';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/solid';

export const TableBody = ({
  data,
  endLink,
  h,
  boolComponents = {},
  maps = {},
  keyPrefix,
  extraColumn,
  cellComponents
}) => {
  const [headersMappings] = useState(Array.from(h.entries()));

  return (
    <tbody className="bg-white divide-y divide-gray-200">
      {data.map((row, i) => (
        <tr key={`${keyPrefix}-${row.id}-${i}`}>
          {headersMappings.map(([header, mapping]) => (
            <td
              key={`${row}-${mapping}`}
              className="px-6 py-4 whitespace-normal"
            >
              {header in boolComponents ? (
                boolComponents[header](row[mapping])
              ) : (
                <>
                  {cellComponents[header] ? (
                    createElement(cellComponents[header], { row })
                  ) : (
                    <div className="text-sm text-gray-900">
                      {mapping in maps
                        ? maps[mapping](row[mapping])
                        : row[mapping]}
                    </div>
                  )}
                </>
              )}
            </td>
          ))}
          {endLink && (
            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
              <div className={'flex justify-end'}>
                <Link to={endLink.replace('<id>', row.id)}>
                  <ChevronRightIcon
                    className="h-7 w-7 whitespace-nowrap text-sm text-black-500"
                    aria-hidden="true"
                  />
                </Link>
              </div>
            </td>
          )}
          {Boolean(extraColumn) && (
            <td
              colspan={extraColumn}
              className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
            />
          )}
        </tr>
      ))}
    </tbody>
  );
};
