import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { fetchIssues } from '../utils/api/clients';
import { CustomTable } from '../components/table/CustomTable';

const headers = new Map([
  ['Repository Name', 'repository_name'],
  ['Title', 'title'],
  ['Comments', 'comments_count'],
  ['State', 'state']
]);

export const Issues = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = React.useState(
    isNaN(parseInt(searchParams.get('page')))
      ? '1'
      : searchParams.get('page') < 1
      ? '1'
      : searchParams.get('page')
  );

  useEffect(() => {
    if (page) {
      setSearchParams({ page });
    }
  }, [page]);

  const { data, isLoading, isFetching, error } = useQuery(
    ['issues', page],
    fetchIssues(page)
  );

  if (error) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <div className="overflow-hidden">
      <div className="md:flex  md:items-center md:justify-between max-w-6xl mx-auto my-6 px-4 sm:px-6 lg:px-8">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Issues
          </h2>
        </div>
      </div>
      <CustomTable
        isLoading={isLoading}
        data={data}
        endLink={`/issues/<id>`}
        h={headers}
        pagination={{ isFetching, page, setPage }}
      />
    </div>
  );
};
