import env from '../../env';

const withCredentials = (input, init) =>
  fetch(input, {
    ...init,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((res) => {
    if (!res.ok) {
      throw Error(`${res.status} ${res.statusText}`);
    }

    if (res.status === 204) return;

    const contentType = res.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      return res.json();
    }
    throw Error('Response was not in JSON format.');
  });

// used for development while HTML response issue still exists
// const withCredentials = async (input, init) => {
//   const data = localStorage.getItem(input);
//   if (data && input !== 'https://sd-fe.onrender.com/api/users/sign_in') {
//     return JSON.parse(data);
//   }
//   const res = await fetch(input, {
//     ...init,
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json'
//     },
//     credentials: 'include'
//   });
//
//   if (!res.ok) {
//     throw Error(`${res.status} ${res.statusText}`);
//   }
//
//   if (res.status === 204) return;
//
//   const contentType = res.headers.get('content-type');
//   if (contentType && contentType.indexOf('application/json') !== -1) {
//     res.json().then((data) => {
//       localStorage.setItem(input, JSON.stringify(data));
//       return data;
//     });
//   }
//   throw Error('Response was not in JSON format.');
// };

export const patchObservation = ({ id, data }) =>
  withCredentials(`${env.api_url}/api/observations/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(data)
  });

export const fetchObservations =
  (page = 1) =>
  () =>
    withCredentials(`${env.api_url}/api/observations?page=${page}`);

export const fetchApplications = () =>
  withCredentials(`${env.api_url}/api/applications`);

export const fetchApplicationById = (id) => () =>
  withCredentials(`${env.api_url}/api/applications/${id}`);

export const fetchApplicationPackageLocks = (id) => () =>
  withCredentials(`${env.api_url}/api/applications/${id}/packagelocks`);

export const fetchPackageLockById = (id) => () =>
  withCredentials(`${env.api_url}/api/packagelocks/${id}`);

export const fetchPackageLockPackages =
  (id, page = 1) =>
  () =>
    withCredentials(
      `${env.api_url}/api/packagelocks/${id}/packages?page=${page}`
    );

export const fetchPackageLockDependencies = (id) => () =>
  withCredentials(`${env.api_url}/api/packagelocks/${id}/dependencies`);

export const fetchPackageLockResults = (id) => () =>
  withCredentials(`${env.api_url}/api/packagelocks/${id}/results`);

export const fetchPackages =
  (page = 1) =>
  () =>
    withCredentials(`${env.api_url}/api/packages?page=${page}`);

export const fetchPackageById = (id) => () =>
  withCredentials(`${env.api_url}/api/packages/${id}`);

export const fetchPackageAdvisories = (id) => () =>
  withCredentials(`${env.api_url}/api/packages/${id}/advisories`);

export const fetchPackageIssues = (id) => () =>
  withCredentials(`${env.api_url}/api/packages/${id}/issues`);

export const fetchIssues =
  (page = 1) =>
  () =>
    withCredentials(`${env.api_url}/api/issues?page=${page}`);

export const fetchIssueById = (id) => () =>
  withCredentials(`${env.api_url}/api/issues/${id}`);

export const fetchPackageContributors = (id) => () =>
  withCredentials(`${env.api_url}/api/packages/${id}/contributors`);

export const postPackageLock = (data) =>
  withCredentials(`${env.api_url}/api/packagelocks`, {
    method: 'POST',
    body: JSON.stringify(data)
  });

export const signIn = (data) =>
  withCredentials(`${env.api_url}/api/users/sign_in`, {
    method: 'POST',
    body: JSON.stringify({ user: data })
  });

export const signUp = (data) =>
  withCredentials(`${env.api_url}/api/users`, {
    method: 'POST',
    body: JSON.stringify({ user: data })
  });

export const getCurrentUser = () =>
  withCredentials(`${env.api_url}/api/users/current`);

export const signOut = () =>
  withCredentials(`${env.api_url}/api/users/sign_out`, {
    method: 'DELETE'
  });

export const fetchContributors =
  (page = 1) =>
  () =>
    withCredentials(`${env.api_url}/api/contributors?page=${page}`);

export const fetchContributorById = (id) => () =>
  withCredentials(`${env.api_url}/api/contributors/${id}`);

export const fetchAdvisories =
  (page = 1) =>
  () =>
    withCredentials(`${env.api_url}/api/advisories?page=${page}`);

export const fetchAdvisoryById = (id) => () =>
  withCredentials(`${env.api_url}/api/advisories/${id}`);
