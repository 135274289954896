export const formatDateMMDDYYYY = (date) => {
  if (!date) return null;
  const [year, month, day] = date.slice(0, 10).split('-');
  return `${month}/${day}/${year}`;
};

export const formatDateMDYYYYHHSS = (date) => {
  if (!date) return null;
  const [year, month, day] = date.slice(0, 10).split('-');
  const [hour, minute] = date.slice(11, 16).split(':');
  const localTimezone = new Date()
    .toLocaleTimeString('en-us', { timeZoneName: 'short' })
    .split(' ')[2];

  const monthToName = {
    '01': 'January',
    '02': 'February',
    '03': 'March',
    '04': 'April',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'August',
    '09': 'September',
    10: 'October',
    11: 'November',
    12: 'December'
  };

  const getDaySuffix = (day) => {
    if (day < 10 || day > 20) {
      switch (day % 10) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
      }
    }
    return 'th';
  };

  return `${monthToName[month]} ${day}${getDaySuffix(
    day
  )} ${year} ${hour}:${minute} ${localTimezone}`;
};

export const capitalizeFirstLetters = (str) => {
  return str
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export const countTrueValues = (obj) => {
  let count = 0;
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (value === true) {
        count++;
      } else if (typeof value === 'object') {
        count += countTrueValues(value);
      }
    }
  }
  return count;
};
