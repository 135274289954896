import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import Loading from '../components/Loading';
import {
  fetchPackageAdvisories,
  fetchPackageById,
  fetchPackageContributors,
  fetchPackageIssues
} from '../utils/api/clients';
import { CustomTable } from '../components/table/CustomTable';

export const Package = () => {
  let { id } = useParams();

  const {
    isLoading: isPackageLoading,
    error: packageError,
    data: packageData
  } = useQuery(['packageData', id], fetchPackageById(id));
  const {
    isLoading: isAdvisoriesLoading,
    error: advisoriesError,
    data: advisoriesData
  } = useQuery(['packageAdvisoriesData', id], fetchPackageAdvisories(id));
  const {
    isLoading: isIssuesLoading,
    error: issuesError,
    data: issuesData
  } = useQuery(['packageIssuesData', id], fetchPackageIssues(id));
  const {
    isLoading: isContributorsLoading,
    error: contributorsError,
    data: contributorsData
  } = useQuery(['packageContributorsData', id], fetchPackageContributors(id));

  if (
    isPackageLoading ||
    isAdvisoriesLoading ||
    isIssuesLoading ||
    isContributorsLoading
  ) {
    return <Loading />;
  }

  if (packageError || advisoriesError || issuesError || contributorsError) {
    return [packageError, advisoriesError, issuesError, contributorsError]
      .filter((err) => err)
      .map((err, i) => <div key={i}>Error: {err.message}</div>);
  }

  return (
    <div className="overflow-hidden">
      <div className="flex flex-col max-w-5xl mx-auto bg-gray-100 m-4">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 bg-gray-100">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b bg-white border-gray-200 sm:rounded-lg">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl m-4 sm:truncate">
                Package: {packageData?.name}
              </h2>
              <div className="sm:col-span-2 my-4 mx-4">
                <div className="text-sm font-medium text-gray-500">
                  Repository URL:{' '}
                  <span className="mt-1 max-w-prose text-sm text-gray-900 space-y-5">
                    {packageData?.repository_url}
                  </span>
                </div>
              </div>
              <div className="sm:col-span-2 my-4 mx-4">
                <div className="text-sm font-medium text-gray-500">
                  Repository Size:{' '}
                  <span className="mt-1 max-w-prose text-sm text-gray-900 space-y-5">
                    {packageData?.repository_size}mb
                  </span>
                </div>
              </div>
              <div className="sm:col-span-2 my-4 mx-4">
                <div className="text-sm font-medium text-gray-500">
                  Contributors:{' '}
                  <span className="mt-1 max-w-prose text-sm text-gray-900 space-y-5">
                    {packageData?.contributor_count}
                  </span>
                </div>
              </div>
              <div className="sm:col-span-2 my-4 mx-4">
                <div className="text-sm font-medium text-gray-500">
                  Subscribers:{' '}
                  <span className="mt-1 max-w-prose text-sm text-gray-900 space-y-5">
                    {packageData?.subscribers_count}
                  </span>
                </div>
              </div>
              <div className="sm:col-span-2 my-4 mx-4">
                <div className="text-sm font-medium text-gray-500">
                  Watchers:{' '}
                  <span className="mt-1 max-w-prose text-sm text-gray-900 space-y-5">
                    {packageData?.watchers_count}
                  </span>
                </div>
              </div>
              <div className="sm:col-span-2 my-4 mx-4">
                <div className="text-sm font-medium text-gray-500">
                  Forks:{' '}
                  <span className="mt-1 max-w-prose text-sm text-gray-900 space-y-5">
                    {packageData?.forks_count}
                  </span>
                </div>
              </div>
              <CustomTable
                isLoading={isAdvisoriesLoading}
                title={'Advisories'}
                data={advisoriesData}
                endLink={`/advisory/<id>`}
                h={
                  new Map([
                    ['Severity', 'severity'],
                    ['CWE-ID', 'cwe_id'],
                    ['Summary', 'summary']
                  ])
                }
              />
              <CustomTable
                isLoading={isIssuesLoading}
                title={'Issues'}
                data={issuesData}
                endLink={`/issues/<id>`}
                h={
                  new Map([
                    ['State', 'state'],
                    ['Number of comments', 'comments_count'],
                    ['Summary', 'title']
                  ])
                }
              />
              <CustomTable
                isLoading={isContributorsLoading}
                title={'Contributors'}
                data={contributorsData}
                endLink={`/contributor/<id>`}
                h={
                  new Map([
                    ['Name', 'login'],
                    ['Number of contributions', 'contributions_count']
                  ])
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
