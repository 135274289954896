import { useQuery } from '@tanstack/react-query';
import { fetchContributors } from '../../utils/api/clients';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CustomTable } from '../table/CustomTable';

const headers = new Map([
  ['Login', 'login'],
  ['Total Contributions', 'contributions_count']
]);

export const ContributorsList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = React.useState(
    isNaN(parseInt(searchParams.get('page')))
      ? '1'
      : searchParams.get('page') < 1
      ? '1'
      : searchParams.get('page')
  );
  const { data, isLoading, isFetching, status, error } = useQuery(
    ['fetchContributors', page],
    fetchContributors(page),
    { keepPreviousData: true }
  );

  useEffect(() => {
    if (page) {
      setSearchParams({ page });
    }
  }, [page]);

  if (status === 'error') {
    return <span>Error: {error.message}</span>;
  }

  if (error) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <div className="overflow-hidden">
      <div className="md:flex  md:items-center md:justify-between max-w-6xl mx-auto my-6 px-4 sm:px-6 lg:px-8">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Issues
          </h2>
        </div>
      </div>
      <CustomTable
        isLoading={isLoading}
        data={data}
        endLink={`/contributor/<id>`}
        h={headers}
        pagination={{ isFetching, page, setPage }}
      />
    </div>
  );
};
