import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { fetchIssueById } from '../utils/api/clients';
import Loading from '../components/Loading';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

export const Issue = () => {
  let { id } = useParams();

  const { data, isLoading, error } = useQuery(
    ['issues', id],
    fetchIssueById(id)
  );

  const { state, title, body, comments_count } = useMemo(() => {
    if (!data) {
      return {};
    }
    const state =
      Array.isArray(data.state) &&
      data.state[0].toUpperCase() + data.state.slice(1);

    return {
      state,
      title: data.title,
      body: data.body,
      comments_count: data.comments_count
    };
  }, [data]);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="max-w-6xl mx-auto my-6 px-4 sm:px-6 lg:px-8">
      <div className="flex flex-col max-w-5xl mx-auto bg-gray-100 ">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 bg-gray-100">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="flex-1 min-w-0 text-center">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate">
                State
              </h2>
              <p>{state}</p>
            </div>
            <div className="flex-1 min-w-0 text-center">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate">
                Summary
              </h2>
              <p>{title}</p>
            </div>
            <div>
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate">
                Details
              </h2>
              {body ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(marked.parse(body))
                  }}
                ></div>
              ) : (
                <p>Nothing found</p>
              )}
            </div>
            <div>
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate">
                Number of comments: <span>{comments_count}</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
