import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { fetchAdvisoryById } from '../utils/api/clients';
import Loading from '../components/Loading';

export const Advisory = () => {
  let { id } = useParams();

  const { data, isLoading, error } = useQuery(
    ['advisory', id],
    fetchAdvisoryById(id)
  );

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="max-w-6xl mx-auto my-6 px-4 sm:px-6 lg:px-8">
      <div className="flex flex-col max-w-5xl mx-auto bg-gray-100 ">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 bg-gray-100">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="flex-1 min-w-0 text-center">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate">
                Package Name
              </h2>
              <p>{data.package_name}</p>
            </div>
            <div className="flex-1 min-w-0 text-center">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate">
                Severity
              </h2>
              <p>{data.severity[0] + data.severity.slice(1).toLowerCase()}</p>
            </div>
            <div className="flex-1 min-w-0 text-center">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate">
                Summary
              </h2>
              <p>{data.summary}</p>
            </div>
            <div>
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate">
                Details
              </h2>
              <p>{data.details}</p>
            </div>
            <div>
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate">
                Affected
              </h2>
              {data.affected?.length ? (
                data.affected.map((affected, i) => (
                  <div key={`affected-${i}`}>
                    {affected.ranges?.length ? (
                      <div>
                        <h3 className="ml-4 font-bold leading-7 text-gray-900 sm:text-lg sm:truncate">
                          Ranges:
                        </h3>
                        {affected.ranges.map(({ type, events }, i) => (
                          <div className={'ml-8'} key={`range-${i}`}>
                            <p>
                              <span className={'font-bold'}>Type:</span>{' '}
                              {type[0] + type.slice(1).toLowerCase()}
                            </p>
                            <div>
                              <p className={'font-bold'}>Events:</p>
                              {events.map((event, i) => (
                                <div key={`event-${i}`}>
                                  {Object.entries(event).map(
                                    ([key, value], i) => (
                                      <p key={`event-key-${i}`}>
                                        <span className={'ml-4 font-bold'}>
                                          {key[0].toUpperCase() + key.slice(1)}:
                                        </span>{' '}
                                        {value}
                                      </p>
                                    )
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p>None</p>
                    )}
                  </div>
                ))
              ) : (
                <p>None</p>
              )}
            </div>
            <div>
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate">
                CWE IDs
              </h2>
              {data.cwe_ids?.length ? (
                <ul className={'list-disc'}>
                  {data.cwe_ids.map((id, i) => (
                    <li className={'ml-6'} key={`cwe-id-${i}`}>
                      {id}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>None</p>
              )}
            </div>
            <div>
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate">
                Aliases
              </h2>
              {data.aliases?.length ? (
                <ul className={'list-disc'}>
                  {data.aliases.map((alias, i) => (
                    <li className={'ml-6'} key={`alias-${i}`}>
                      {alias}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>None</p>
              )}
            </div>
            <div>
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate">
                Severities
              </h2>
              {data.severities?.length ? (
                <div className={'list-disc'}>
                  {data.severities.map((severity, i) => (
                    <div className={'ml-6'} key={`severity-${i}`}>
                      <p>
                        <span className={'font-bold'}>Type: </span>
                        {severity.type}
                      </p>
                      <p>
                        <span className={'font-bold'}>Score: </span>
                        {severity.score}
                      </p>
                    </div>
                  ))}
                </div>
              ) : (
                <p>None</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
