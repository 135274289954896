import React from 'react';
import { Link } from 'react-router-dom';
import logo from './../../logo_abbr_sd.svg';

const MarketingTopNav = () => {
  return (
    <nav className="mt-6 mx-6 flex justify-between">
      <div>
        <Link to="/">
          <img
            className="h-6 mt-6 w-auto"
            src={logo}
            alt="Secure Development"
          />
        </Link>
      </div>
      <div className="items-center justify-between flex">
        <Link to="/signin">
          <p className="whitespace-nowrap text-sm md:text-base font-medium text-gray-500 hover:text-gray-900">
            Sign in
          </p>
        </Link>
        <Link to="/signup">
          <p className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm md:text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700">
            Sign up
          </p>
        </Link>
      </div>
    </nav>
  );
};

export default MarketingTopNav;
