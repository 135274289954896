import { CheckCircleIcon } from '@heroicons/react/solid';
import React from 'react';

export const CheckOrX = (bool) => {
  return bool ? (
    <CheckCircleIcon
      className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400 "
      aria-hidden="true"
    />
  ) : (
    <CheckCircleIcon
      className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400 "
      aria-hidden="true"
    />
  );
};
