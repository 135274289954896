import React, { useEffect, useMemo, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  fetchApplicationById,
  fetchApplicationPackageLocks,
  fetchPackageAdvisories,
  fetchPackageIssues,
  fetchPackageLockById,
  fetchPackageLockDependencies,
  fetchPackageLockPackages,
  fetchPackageLockResults
} from '../utils/api/clients';
import { formatDateMDYYYYHHSS } from '../utils';
import { CustomTable } from '../components/table/CustomTable';
import { CheckOrX } from '../components/bool-components/CheckOrX';
import Loading from '../components/Loading';

const fullDependenciesBoolComponents = {
  Issues: CheckOrX,
  Advisories: CheckOrX
};

const paginateData = (data, pageNumber) => {
  if (!Array.isArray(data)) return [];
  const pageSize = 10;
  const startIndex = (pageNumber - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  return data.slice(startIndex, endIndex);
};

export const Scans = () => {
  let { id } = useParams();
  const [concerningIssuesPage, setConcerningIssuesPage] = useState(1);
  const [advisoriesPage, setAdvisoriesPage] = useState(1);
  const [fullDependenciesPage, setFullDependenciesPage] = useState(1);

  const applicationQuery = useQuery(
    ['applicationData', id],
    fetchApplicationById(id)
  );

  const packageLockQuery = useQuery(
    ['applicationPackageLock', id],
    fetchPackageLockById(id)
  );

  const dependenciesQuery = useQuery(
    ['applicationPackageLockDependencies', id],
    fetchPackageLockDependencies(id)
  );

  const resultsQuery = useQuery(
    ['applicationPackageLockResults', id],
    fetchPackageLockResults(id)
  );

  const packagesQuery = useQuery(
    ['applicationPackageLockPackages', id, fullDependenciesPage],
    fetchPackageLockPackages(id, fullDependenciesPage)
  );

  const {
    mostDependencies,
    fewestContributors,
    mostConcerningIssues,
    mostOpenIssues,
    largestRepository,
    leastPopular,
    mostAdvisories,
    concerningIssues,
    advisories
  } = useMemo(() => {
    const d =
      resultsQuery.data?.length && resultsQuery.data[0]
        ? resultsQuery.data[0]
        : {};
    const result = {
      mostDependencies: d.packages_with_most_dependencies,
      fewestContributors: d.packages_with_fewest_contributors,
      mostConcerningIssues: d.packages_with_most_concerning_issues,
      mostOpenIssues: d.packages_with_most_open_issues,
      largestRepository: d.largest_packages,
      leastPopular: d.packages_with_least_popularity,
      mostAdvisories: d.packages_with_most_advisories,
      concerningIssues: d.concerning_issues,
      advisories: d.advisories?.flat()
    };

    return result;
  }, [resultsQuery.data]);

  const paginatedConcerningIssues = useMemo(() => {
    return paginateData(concerningIssues, concerningIssuesPage);
  }, [concerningIssues, concerningIssuesPage]);

  const paginatedAdvisories = useMemo(() => {
    return paginateData(advisories, advisoriesPage);
  }, [advisories, advisoriesPage]);

  if (
    applicationQuery.isError ||
    packageLockQuery.isError ||
    resultsQuery.isError ||
    packagesQuery.isError ||
    dependenciesQuery.isError
  ) {
    return [
      applicationQuery.error,
      packageLockQuery.error,
      resultsQuery.error,
      packagesQuery.error,
      dependenciesQuery.error
    ]
      .filter((err) => err)
      .map((err, i) => <div key={`error-${i}`}>Error: {err.message}</div>);
  }

  return (
    <div className="overflow-hidden px-8 pb-8">
      {/* Subheader */}
      <div className="md:flex  md:justify-between max-w-6xl my-6 px-4">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate">
            Application: {applicationQuery.data?.name}
          </h2>
          <p>
            Scan time: {formatDateMDYYYYHHSS(packageLockQuery.data?.updated_at)}
          </p>
          <p>Version: {packageLockQuery.data?.version}</p>
        </div>
      </div>
      <div className="px-8">
        <div className="text-lg font-bold mb-2">Table of Contents</div>
        <div className="mb-2">
          <a
            href="#direct-dependencies"
            className="text-blue-500 hover:text-blue-800"
          >
            Direct Dependencies
          </a>
        </div>
        <div className="mb-2">
          <a href="#advisories" className="text-blue-500 hover:text-blue-800">
            Advisories
          </a>
        </div>
        <div className="mb-2">
          <a
            href="#full-dependency-list"
            className="text-blue-500 hover:text-blue-800"
          >
            Full Dependency List
          </a>
        </div>

        <div className="mb-2">
          <a href="#highlights" className="text-blue-500 hover:text-blue-800">
            Highlights
          </a>
          <ul className="ml-4">
            <li className="mb-2">
              <a
                href="#most-dependencies"
                className="text-blue-500 hover:text-blue-800"
              >
                Most Dependencies
              </a>
            </li>
            <li className="mb-2">
              <a
                href="#fewest-contributors"
                className="text-blue-500 hover:text-blue-800"
              >
                Fewest Contributors
              </a>
            </li>
            <li className="mb-2">
              <a
                href="#most-concerning-issues"
                className="text-blue-500 hover:text-blue-800"
              >
                Most Concerning Issues
              </a>
            </li>
            <li className="mb-2">
              <a
                href="#most-open-issues"
                className="text-blue-500 hover:text-blue-800"
              >
                Most Open Issues
              </a>
            </li>
            <li className="mb-2">
              <a
                href="#largest-repository"
                className="text-blue-500 hover:text-blue-800"
              >
                Largest Repository
              </a>
            </li>
            <li className="mb-2">
              <a
                href="#least-popular"
                className="text-blue-500 hover:text-blue-800"
              >
                Least Popular
              </a>
            </li>
            <li className="mb-2">
              <a
                href="#most-advisories"
                className="text-blue-500 hover:text-blue-800"
              >
                Most Advisories
              </a>
            </li>
          </ul>
        </div>

        <div className="mb-2">
          <a
            href="#concerning-issues"
            className="text-blue-500 hover:text-blue-800"
          >
            Concerning Issues
          </a>
        </div>
      </div>
      <CustomTable
        id={'direct-dependencies'}
        data={dependenciesQuery.data}
        isLoading={dependenciesQuery.isLoading}
        h={
          new Map([
            ['Package Name', 'name'],
            ['Version Range', 'version']
          ])
        }
        title={'Direct Dependencies'}
        className={`flex flex-col max-w-xl`}
      />
      <CustomTable
        isLoading={resultsQuery.isLoading}
        id={'advisories'}
        title={'Advisories'}
        h={
          new Map([
            ['Name', 'package_name'],
            ['Severity', 'severity'],
            ['Summary', 'summary']
          ])
        }
        data={paginatedAdvisories}
        className={`flex flex-col max-w-3xl`}
        pagination={{
          isFetching: resultsQuery.isFetching,
          page: advisoriesPage,
          setPage: setAdvisoriesPage
        }}
        extraColumn={1}
      />
      <CustomTable
        isLoading={packagesQuery.isLoading}
        id={'full-dependency-list'}
        data={packagesQuery.data}
        h={
          new Map([
            ['Package Name', 'name'],
            ['Dependencies', 'dependencies_count'],
            ['Issues', 'has_concerning_issues?'],
            ['Advisories', 'has_advisories?'],
            ['Advisory Count', 'advisory_count'],
            ['Contributors', 'contributor_count'],
            ['Popularity', 'popularity'],
            ['Repo Size', 'repository_size']
          ])
        }
        title={'Full dependency list'}
        boolComponents={fullDependenciesBoolComponents}
        className={`flex flex-col max-w-6xl`}
        endLink={'/packages/<id>'}
        pagination={{
          isFetching: packagesQuery.isFetching,
          page: fullDependenciesPage,
          setPage: setFullDependenciesPage
        }}
      />
      <div
        id="highlights"
        className="md:flex  md:items-center md:justify-between max-w-6xl -mb-4 px-4"
      >
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate">
            Highlights
          </h2>
        </div>
      </div>
      <CustomTable
        isLoading={resultsQuery.isLoading}
        id={'most-dependencies'}
        title={'Most dependencies'}
        h={
          new Map([
            ['Name', 'name'],
            ['Dependencies Count', 'dependencies_count']
          ])
        }
        data={mostDependencies}
        className={`flex flex-col max-w-xl`}
        titleClassName={
          'font-bold leading-7 text-gray-900 sm:text-md sm:truncate'
        }
      />
      <CustomTable
        isLoading={resultsQuery.isLoading}
        id={'fewest-contributors'}
        title={'Fewest contributors'}
        h={
          new Map([
            ['Name', 'name'],
            ['Contributors', 'contributor_count']
          ])
        }
        data={fewestContributors}
        className={`flex flex-col max-w-xl`}
        titleClassName={
          'font-bold leading-7 text-gray-900 sm:text-md sm:truncate'
        }
      />
      <CustomTable
        isLoading={resultsQuery.isLoading}
        id={'most-concerning-issues'}
        title={'Most concerning issues'}
        h={
          new Map([
            ['Name', 'name'],
            ['Issues Count', 'issues_count']
          ])
        }
        data={mostConcerningIssues}
        className={`flex flex-col max-w-xl`}
        titleClassName={
          'font-bold leading-7 text-gray-900 sm:text-md sm:truncate'
        }
      />
      <CustomTable
        isLoading={resultsQuery.isLoading}
        id={'most-open-issues'}
        title={'Most open issues'}
        h={
          new Map([
            ['Name', 'name'],
            ['Issues Count', 'issues_count']
          ])
        }
        data={mostOpenIssues}
        className={`flex flex-col max-w-xl`}
        titleClassName={
          'font-bold leading-7 text-gray-900 sm:text-md sm:truncate'
        }
      />
      <CustomTable
        isLoading={resultsQuery.isLoading}
        id={'largest-repository'}
        title={'Largest repository'}
        h={
          new Map([
            ['Name', 'name'],
            ['Size', 'size']
          ])
        }
        data={largestRepository}
        className={`flex flex-col max-w-xl`}
        titleClassName={
          'font-bold leading-7 text-gray-900 sm:text-md sm:truncate'
        }
      />
      <CustomTable
        isLoading={resultsQuery.isLoading}
        id={'least-popular'}
        title={'Least popular'}
        h={
          new Map([
            ['Name', 'name'],
            ['Popularity', 'popularity']
          ])
        }
        data={leastPopular}
        className={`flex flex-col max-w-xl`}
        titleClassName={
          'font-bold leading-7 text-gray-900 sm:text-md sm:truncate'
        }
      />
      <CustomTable
        isLoading={resultsQuery.isLoading}
        id={'most-advisories'}
        title={'Most advisories'}
        h={
          new Map([
            ['Name', 'name'],
            ['Advisories Count', 'advisory_count']
          ])
        }
        data={mostAdvisories}
        className={`flex flex-col max-w-xl`}
        titleClassName={
          'font-bold leading-7 text-gray-900 sm:text-md sm:truncate'
        }
      />
      <CustomTable
        isLoading={resultsQuery.isLoading}
        id={'concerning-issues'}
        title={'Concerning Issues'}
        h={
          new Map([
            ['Repository', 'repository_name'],
            ['Title', 'title'],
            ['Status', 'state']
          ])
        }
        data={paginatedConcerningIssues}
        className={`flex flex-col max-w-6xl`}
        pagination={{
          isFetching: resultsQuery.isFetching,
          page: concerningIssuesPage,
          setPage: setConcerningIssuesPage
        }}
        extraColumn={1}
      />
    </div>
  );
};
