import React, { useState } from 'react';

export const TableHeader = ({ h, endCell, keyPrefix }) => {
  const [headers] = useState(h);
  return (
    <>
      <thead className="bg-gray-50">
        <tr>
          {headers.map((header, i) => (
            <th
              key={`${keyPrefix}-${header}-${i}`}
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
            >
              {header}
            </th>
          ))}
          {endCell && (
            <th scope="col" className="relative px-6 py-3">
              <span className="sr-only">Edit</span>
            </th>
          )}
        </tr>
      </thead>
    </>
  );
};
