import React, { useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  fetchApplicationById,
  fetchApplicationPackageLocks
} from '../utils/api/clients';
import {
  CheckCircleIcon,
  XCircleIcon,
  ChevronRightIcon
} from '@heroicons/react/solid';
import { formatDateMMDDYYYY } from '../utils';

export const Application = () => {
  let { id } = useParams();

  const applicationQuery = useQuery(
    ['applicationData', id],
    fetchApplicationById(id)
  );

  const packageLocksQuery = useQuery(
    ['applicationPackageLocks', id],
    fetchApplicationPackageLocks(id)
  );

  if (applicationQuery.isLoading || packageLocksQuery.isLoading) {
    return (
      <div className="overflow-hidden">
        {/* Subheader */}
        <div className="md:flex  md:items-center md:justify-between max-w-6xl mx-auto my-6 px-4 sm:px-6 lg:px-8">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              Applications
            </h2>
          </div>
          <div className="mt-4 flex md:mt-0 md:ml-4">
            <Link to="/analyze">
              <button
                type="button"
                className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                New application
              </button>
            </Link>
          </div>
        </div>

        <div className="flex flex-col max-w-5xl mx-auto bg-gray-100 ">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 bg-gray-100">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                      >
                        Scan Date
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                      >
                        Dependencies
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                      >
                        Issues
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                      >
                        Advisories
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    <tr>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        <div className="w-36 h-6 bg-gray-300 animate-pulse rounded-sm "></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="w-36 h-6 bg-gray-300 animate-pulse rounded-sm "></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="w-20 h-6 bg-gray-300  animate-pulse rounded-sm "></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="w-6 h-6 bg-gray-300  animate-pulse rounded-sm "></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <div className="w-36 h-6 bg-gray-300  animate-pulse rounded-sm "></div>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        <div className="w-36 h-6 bg-gray-300 animate-pulse rounded-sm "></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="w-36 h-6 bg-gray-300 animate-pulse rounded-sm "></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="w-20 h-6 bg-gray-300  animate-pulse rounded-sm "></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="w-6 h-6 bg-gray-300  animate-pulse rounded-sm "></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <div className="w-36 h-6 bg-gray-300  animate-pulse rounded-sm "></div>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        <div className="w-36 h-6 bg-gray-300 animate-pulse rounded-sm "></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="w-36 h-6 bg-gray-300 animate-pulse rounded-sm "></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="w-20 h-6 bg-gray-300  animate-pulse rounded-sm "></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="w-6 h-6 bg-gray-300  animate-pulse rounded-sm "></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <div className="w-36 h-6 bg-gray-300  animate-pulse rounded-sm "></div>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        <div className="w-36 h-6 bg-gray-300 animate-pulse rounded-sm "></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="w-36 h-6 bg-gray-300 animate-pulse rounded-sm "></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="w-20 h-6 bg-gray-300  animate-pulse rounded-sm "></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="w-6 h-6 bg-gray-300  animate-pulse rounded-sm "></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <div className="w-36 h-6 bg-gray-300  animate-pulse rounded-sm "></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (applicationQuery.isError || packageLocksQuery.isError) {
    return [applicationQuery.error, packageLocksQuery.error]
      .filter((err) => err)
      .map((err, i) => <div key={i}>Error: {err.message}</div>);
  }

  return (
    <div className="overflow-hidden">
      {/* Subheader */}
      <div className="md:flex  md:items-center md:justify-between max-w-6xl mx-auto my-6 px-4 sm:px-6 lg:px-8">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate">
            Application: {applicationQuery.data?.name}
          </h2>
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <Link to="/analyze">
            <button
              type="button"
              className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              New application
            </button>
          </Link>
        </div>
      </div>

      <div className="flex flex-col max-w-5xl mx-auto bg-gray-100 ">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 bg-gray-100">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      Scan Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      Dependencies
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      Issue-free
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      Advisories
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">View</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {packageLocksQuery.data?.map((packageLock, i) => (
                    <tr key={i}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {formatDateMMDDYYYY(packageLock.updated_at)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {packageLock.dependency_count}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {packageLock['issues?'] ? (
                          <CheckCircleIcon
                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400 "
                            aria-hidden="true"
                          />
                        ) : (
                          <XCircleIcon
                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400 "
                            aria-hidden="true"
                          />
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {packageLock['advisories?'] ? (
                          <CheckCircleIcon
                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400 "
                            aria-hidden="true"
                          />
                        ) : (
                          <XCircleIcon
                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400 "
                            aria-hidden="true"
                          />
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <Link to={`/application/${packageLock['id']}/scans`}>
                          <ChevronRightIcon
                            className="h-7 w-7 whitespace-nowrap text-sm text-black-500"
                            aria-hidden="true"
                          />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
