/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useContext } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { UserContext } from '../contexts/UserContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import logo from './../logo_abbr_sd.svg';
import { signOut } from '../utils/api/clients';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const TopNav = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { setCurrentUser, currentUser } = useContext(UserContext);

  const handleSignOut = () => {
    signOut().then(() => {
      setCurrentUser(null);
      navigate('/');
    });
  };

  return (
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <Link to="/">
                    <img
                      className="block lg:hidden h-8 w-auto"
                      src={logo}
                      alt="Secure Development"
                    />
                    <img
                      className="hidden lg:block h-8 w-auto "
                      src={logo}
                      alt="Secure Development"
                    />
                  </Link>
                </div>

                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                  {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                  <Link
                    to="/applications"
                    className={`border-indigo-500 text-gray-900 inline-flex items-center px-1 pt-1 ${
                      location.pathname === '/applications' ? 'border-b-2' : ''
                    } text-sm font-medium`}
                  >
                    Applications
                  </Link>
                  <Link
                    to="/contributors?page=1"
                    className={`border-indigo-500 text-gray-900 inline-flex items-center px-1 pt-1 ${
                      location.pathname.includes('/contributors')
                        ? 'border-b-2'
                        : ''
                    } text-sm font-medium`}
                  >
                    Contributors
                  </Link>
                  <Link
                    to="/packages"
                    className={`border-indigo-500 text-gray-900 inline-flex items-center px-1 pt-1 ${
                      location.pathname.includes('/packages')
                        ? 'border-b-2'
                        : ''
                    } text-sm font-medium`}
                  >
                    Packages
                  </Link>
                  <Link
                    to="/advisories"
                    className={`border-indigo-500 text-gray-900 inline-flex items-center px-1 pt-1 ${
                      location.pathname.includes('/advisories')
                        ? 'border-b-2'
                        : ''
                    } text-sm font-medium`}
                  >
                    Advisories
                  </Link>
                  <Link
                    to="/issues"
                    className={`border-indigo-500 text-gray-900 inline-flex items-center px-1 pt-1 ${
                      location.pathname.includes('/issues') ? 'border-b-2' : ''
                    } text-sm font-medium`}
                  >
                    Issues
                  </Link>
                  <Link
                    to="/observations"
                    className={`border-indigo-500 text-gray-900 inline-flex items-center px-1 pt-1 ${
                      location.pathname.includes('/observations')
                        ? 'border-b-2'
                        : ''
                    } text-sm font-medium`}
                  >
                    Observations
                  </Link>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <span className="sr-only">Open user menu</span>
                      <span className="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-100">
                        <svg
                          className="h-full w-full text-gray-300"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      </span>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            )}
                          >
                            Account
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            )}
                            onClick={handleSignOut}
                          >
                            Sign out
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="pt-2 pb-4 space-y-1">
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
              <Link
                to="/applications"
                className={`bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 text-base font-medium ${
                  location.pathname === '/applications' ? 'border-l-4' : ''
                }`}
              >
                Applications
              </Link>
              <Link
                to="/contributors"
                className={`bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 text-base font-medium ${
                  location.pathname === '/contributors' ? 'border-l-4' : ''
                }`}
              >
                Contributors
              </Link>
              <Link
                to="/packages"
                className={`bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 text-base font-medium ${
                  location.pathname === '/packages' ? 'border-l-4' : ''
                }`}
              >
                Packages
              </Link>
              <Link
                to="/advisories"
                className={`bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 text-base font-medium ${
                  location.pathname === '/advisories' ? 'border-l-4' : ''
                }`}
              >
                Advisories
              </Link>
              <Link
                to="/issues"
                className={`bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 text-base font-medium ${
                  location.pathname === '/issues' ? 'border-l-4' : ''
                }`}
              >
                Issues
              </Link>
              <Link
                to="/observations"
                className={`bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 text-base font-medium ${
                  location.pathname === '/observations' ? 'border-l-4' : ''
                }`}
              >
                Observations
              </Link>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default TopNav;
