import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { postPackageLock } from '../utils/api/clients';

export const Analyze = () => {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    postPackageLock({
      packagelock: {
        contents_json: data.dependencies
      }
    }).catch((err) => console.log(err));
    navigate('/applications');
  };

  const onCancel = () => {
    navigate('/applications');
  };

  return (
    <div className="overflow-hidden">
      {/* Subheader */}
      <div className="md:flex  md:items-center md:justify-between max-w-6xl mx-auto my-6 px-4 sm:px-6 lg:px-8">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Add application
          </h2>
        </div>
      </div>

      <div className="flex flex-col max-w-5xl mx-auto bg-gray-100 ">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 bg-gray-100">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b bg-white border-gray-200 sm:rounded-lg">
              <div className="sm:col-span-2 my-4 mx-4 ">
                <form className="space-y-8" onSubmit={handleSubmit(onSubmit)}>
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="about"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Paste your package-lock.json file
                    </label>
                    <div className="mt-1">
                      <textarea
                        id="dependencies"
                        name="dependencies"
                        rows={16}
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                        defaultValue={''}
                        {...register('dependencies')}
                      />
                    </div>
                    <p className="mt-2 text-sm text-gray-500">
                      Package-lock.json is a large file and could take a few
                      moments to paste.
                    </p>
                  </div>

                  <div className="mt-5">
                    <div className="flex justify-end">
                      <button
                        type="button"
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={onCancel}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Add application
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
