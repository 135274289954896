import { getCurrentUser } from '../../utils/api/clients';
import { useContext } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { UserContext } from '../../contexts/UserContext';

export const RefreshUser = () => {
  const { setCurrentUser } = useContext(UserContext);
  const queryClient = useQueryClient();
  const { data } = useQuery(['currentUser'], getCurrentUser, {
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setCurrentUser(data);
    },
    onError: (_) => {
      setCurrentUser(null);
    }
  });

  return null;
};
