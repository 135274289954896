import { Link, useSearchParams } from 'react-router-dom';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { fetchObservations, patchObservation } from '../utils/api/clients';
import { CustomTable } from '../components/table/CustomTable';
import { capitalizeFirstLetters, countTrueValues } from '../utils';

const headers = new Map([
  ['Application', 'application_name'],
  ['Type', 'observation_type'],
  ['Package', 'package_name'],
  ['Version', 'version'],
  ['Description', 'description'],
  ['Severity', 'severity'],
  ['Status', 'status']
]);

const maps = {
  observation_type: capitalizeFirstLetters,
  severity: capitalizeFirstLetters,
  status: capitalizeFirstLetters
};

function Application({ row }) {
  return (
    <Link to={`/application/${row['application_id']}`}>
      <div className="text-sm  text-blue-500 underline">
        {row['application_name']}
      </div>
    </Link>
  );
}

function Package({ row }) {
  return (
    <Link to={`/packages/${row['package_id']}`}>
      <div className="text-sm  text-blue-500 underline">
        {row['package_name']}
      </div>
    </Link>
  );
}

function Description({ row }) {
  return (
    <div>
      <div className="text-sm">{row['description']}</div>
      <div className={'text-blue-500 underline'}>
        <Link to={`/${row['related_link']}`}>View Details</Link>
      </div>
    </div>
  );
}

const cellComponents = {
  Application,
  Package,
  Description,
  Status
};

function Status({ row }) {
  const { mutateAsync } = useMutation(
    ['observations', { id: row.id }],
    patchObservation
  );
  const [selectedOption, setSelectedOption] = useState(
    row['status'] && row['status'].toLowerCase()
  );
  const [isDisabled, setIsDisabled] = useState(false);

  const handleChange = (event) => {
    setIsDisabled(true);
    const value = event.target.value;

    mutateAsync({ id: row.id, data: { status: value } })
      .then(({ status }) => {
        setSelectedOption(status);
        setIsDisabled(false);
      })
      .catch(() => {
        setIsDisabled(false);
      });
  };
  return (
    <div>
      <select
        disabled={isDisabled}
        style={{ width: 'auto', display: 'inline-block' }}
        value={selectedOption}
        onChange={handleChange}
        className={`form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 ${
          isDisabled ? 'animate-pulse bg-gray-300 cursor-not-allowed' : ''
        }`}
      >
        <option value="open">Open</option>
        <option value="acknowledged">Acknowledged</option>
        <option value="resolved">Resolved</option>
        <option value="not applicable">Not Applicable</option>
      </select>
    </div>
  );
}

export const Observations = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(
    isNaN(parseInt(searchParams.get('page')))
      ? '1'
      : searchParams.get('page') < 1
      ? '1'
      : searchParams.get('page')
  );

  useEffect(() => {
    if (page) {
      setSearchParams({ page });
    }
  }, [page]);

  const { data, isLoading, isFetching, error } = useQuery(
    ['observations', { page }],
    fetchObservations(page),
    { refetchOnWindowFocus: false }
  );

  const [filters, setFilters] = useState({
    severity: { HIGH: true, MODERATE: true, LOW: true }
  });

  const numFiltersApplied = useMemo(() => {
    return countTrueValues(filters);
  }, [filters]);
  const filteredData = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.filter((row) => {
      return filters.severity[row.severity];
    });
  }, [data, filters]);

  const handleFilterChange = (e) => {
    const { name, value, checked } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: {
        ...prevFilters[name],
        [value]: checked
      }
    }));
  };

  if (error) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <div className="overflow-hidden">
      <div className="md:flex  md:items-center md:justify-between max-w-6xl mx-auto my-6 px-4 sm:px-6 lg:px-8">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Observations
          </h2>
        </div>
      </div>
      <CustomTable
        filterComponent={{
          component: FilterComponent,
          props: { filters, handleFilterChange },
          numFiltersApplied
        }}
        cellComponents={cellComponents}
        maps={maps}
        isLoading={isLoading}
        data={filteredData}
        h={headers}
        pagination={{ isFetching, page, setPage }}
      />
    </div>
  );
};

const FilterComponent = forwardRef(({ filters, handleFilterChange }, ref) => {
  return (
    <div ref={ref}>
      {Object.entries(filters).map(([filterName, filterValues]) => (
        <div key={filterName}>
          <h3>{capitalizeFirstLetters(filterName)}:</h3>
          {Object.entries(filterValues).map(([value, isChecked]) => (
            <div key={value}>
              <div className={'flex align-center'}>
                <div className={'p-1'}>
                  <input
                    type="checkbox"
                    name={filterName}
                    value={value}
                    checked={isChecked}
                    onChange={handleFilterChange}
                  />
                </div>
                <div className={'p-1'}>
                  <label>{capitalizeFirstLetters(value)}</label>
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
});
