import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom';
import MarketingTopNav from './components/marketing/MarketingTopNav';
import TopNav from './components/TopNav';
import { UserContext } from './contexts/UserContext.js';
import PrivateRoute from './utils/PrivateRoute';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RefreshUser } from './components/auth';
import {
  Advisories,
  Advisory,
  Analyze,
  Application,
  Applications,
  Contributor,
  Contributors,
  Landing,
  Package,
  Packages,
  Scans,
  Signin,
  Signup,
  Issue,
  Issues,
  Observations
} from './pages';

const App = () => {
  const [currentUser, setCurrentUser] = useState();
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <UserContext.Provider value={{ setCurrentUser, currentUser }}>
        <RefreshUser />
        <Router>
          <div className={`${currentUser ? 'bg-gray-100 min-h-screen' : null}`}>
            <nav>
              {currentUser ? <TopNav /> : <MarketingTopNav />}
              <Routes>
                <Route path="/signin" element={<Signin />} />
                <Route path="/signup" element={<Signup />} />
                <Route
                  path="/analyze"
                  element={
                    <PrivateRoute>
                      <Analyze />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/"
                  element={
                    currentUser ? <Navigate to="/applications" /> : <Landing />
                  }
                />
                <Route
                  path="/applications"
                  element={
                    <PrivateRoute>
                      <Applications />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/application/:id"
                  element={
                    <PrivateRoute>
                      <Application />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/application/:id/scans"
                  element={
                    <PrivateRoute>
                      <Scans />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={'/contributors'}
                  element={
                    <PrivateRoute>
                      <Contributors />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={'/contributor/:id'}
                  element={
                    <PrivateRoute>
                      <Contributor />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={'/advisories'}
                  element={
                    <PrivateRoute>
                      <Advisories />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={'/advisory/:id'}
                  element={
                    <PrivateRoute>
                      <Advisory />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={'/packages'}
                  element={
                    <PrivateRoute>
                      <Packages />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/packages/:id"
                  element={
                    <PrivateRoute>
                      <Package />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/issues"
                  element={
                    <PrivateRoute>
                      <Issues />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/issues/:id"
                  element={
                    <PrivateRoute>
                      <Issue />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/observations"
                  element={
                    <PrivateRoute>
                      <Observations />
                    </PrivateRoute>
                  }
                />
              </Routes>
            </nav>
          </div>
        </Router>
      </UserContext.Provider>
    </QueryClientProvider>
  );
};

export default App;
