import React from 'react';

const Loading = () => {
  return (
    <div className="overflow-hidden">
      <div className="md:flex  md:items-center md:justify-between max-w-6xl mx-auto my-6 px-4 sm:px-6 lg:px-8">
        <div className="flex-1 min-w-0">
          <div className="w-2/5 h-8 bg-gray-300 animate-pulse rounded-sm "></div>
        </div>
      </div>

      <div className="flex flex-col max-w-5xl mx-auto bg-gray-100 ">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 bg-gray-100">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b bg-white border-gray-200 sm:rounded-lg">
              <div className="sm:col-span-2 my-4 mx-4">
                <div className="text-sm font-medium text-gray-500">
                  <div className="w-36 h-5 bg-gray-300 animate-pulse rounded-sm "></div>
                </div>
                <div className="mt-1 max-w-prose text-sm text-gray-900 space-y-5">
                  <div className="w-36 h-5 bg-gray-300 animate-pulse rounded-sm "></div>
                </div>
              </div>

              <div className="sm:col-span-2 my-4 mx-4">
                <div className="text-sm font-medium text-gray-500">
                  <div className="w-36 h-5 bg-gray-300 animate-pulse rounded-sm "></div>
                </div>
                <div className="mt-1 max-w-prose text-sm text-gray-900 space-y-5">
                  <div className="w-36 h-5 bg-gray-300 animate-pulse rounded-sm "></div>
                </div>
              </div>

              <div className="sm:col-span-2 my-4 mx-4">
                <div className="text-sm font-medium text-gray-500">
                  <div className="w-36 h-5 bg-gray-300 animate-pulse rounded-sm "></div>
                </div>
                <div className="mt-1 max-w-prose text-sm text-gray-900 space-y-5">
                  <div className="w-36 h-5 bg-gray-300 animate-pulse rounded-sm "></div>
                </div>
              </div>

              <div className="sm:col-span-2 my-4 mx-4">
                <div className="text-sm font-medium text-gray-500">
                  <div className="w-36 h-5 bg-gray-300 animate-pulse rounded-sm "></div>
                </div>
                <div className="mt-1 max-w-prose text-sm text-gray-900 space-y-5">
                  <div className="w-36 h-5 bg-gray-300 animate-pulse rounded-sm "></div>
                </div>
              </div>

              <div className="sm:col-span-2 my-4 mx-4">
                <div className="text-sm font-medium text-gray-500">
                  <div className="w-36 h-5 bg-gray-300 animate-pulse rounded-sm "></div>
                </div>
                <div className="mt-1 max-w-prose text-sm text-gray-900 space-y-5">
                  <div className="w-36 h-5 bg-gray-300 animate-pulse rounded-sm "></div>
                </div>
              </div>

              <div className="md:flex  md:items-center md:justify-between max-w-6xl mx-auto my-6 px-4 ">
                <div className="flex-1 min-w-0">
                  <div className="w-40 h-7 bg-gray-300 animate-pulse rounded-sm "></div>
                </div>
              </div>

              <div className="sm:col-span-2 my-4 mx-4">
                <div className="text-sm font-medium text-gray-500">
                  <div className="w-36 h-5 bg-gray-300 animate-pulse rounded-sm "></div>
                </div>
                <div className="mt-1 max-w-prose text-sm text-gray-900 space-y-1">
                  <div className="w-36 h-5 bg-gray-300 animate-pulse rounded-sm "></div>
                  <div className="w-36 h-5 bg-gray-300 animate-pulse rounded-sm "></div>
                  <div className="w-36 h-5 bg-gray-300 animate-pulse rounded-sm "></div>
                  <div className="w-36 h-5 bg-gray-300 animate-pulse rounded-sm "></div>
                  <div className="w-36 h-5 bg-gray-300 animate-pulse rounded-sm "></div>
                </div>
              </div>

              <div className="sm:col-span-2 my-4 mx-4">
                <div className="text-sm font-medium text-gray-500">
                  <div className="w-36 h-5 bg-gray-300 animate-pulse rounded-sm "></div>
                </div>
                <div className="mt-1 max-w-prose text-sm text-gray-900 space-y-1">
                  <div className="w-36 h-5 bg-gray-300 animate-pulse rounded-sm "></div>
                  <div className="w-36 h-5 bg-gray-300 animate-pulse rounded-sm "></div>
                  <div className="w-36 h-5 bg-gray-300 animate-pulse rounded-sm "></div>
                  <div className="w-36 h-5 bg-gray-300 animate-pulse rounded-sm "></div>
                  <div className="w-36 h-5 bg-gray-300 animate-pulse rounded-sm "></div>
                </div>
              </div>

              <div className="md:flex  md:items-center md:justify-between max-w-6xl mx-auto my-6 px-4 ">
                <div className="flex-1 min-w-0">
                  <div className="w-1/2 h-7 bg-gray-300 animate-pulse rounded-sm "></div>
                </div>
              </div>

              <div className="sm:col-span-2 my-4 mx-4">
                <div className="mt-1 max-w-prose text-sm text-gray-900 space-y-1">
                  <div className="w-36 h-5 bg-gray-300 animate-pulse rounded-sm "></div>
                  <div className="w-36 h-5 bg-gray-300 animate-pulse rounded-sm "></div>
                  <div className="w-36 h-5 bg-gray-300 animate-pulse rounded-sm "></div>
                  <div className="w-36 h-5 bg-gray-300 animate-pulse rounded-sm "></div>
                  <div className="w-36 h-5 bg-gray-300 animate-pulse rounded-sm "></div>
                </div>
              </div>

              <div className="md:flex  md:items-center md:justify-between max-w-6xl mx-auto my-6 px-4">
                <div className="flex-1 min-w-0">
                  <div className="w-1/2 h-7 bg-gray-300 animate-pulse rounded-sm "></div>
                </div>
              </div>

              <div className="sm:col-span-2 my-4 mx-4">
                <div className="mt-1 max-w-prose text-sm text-gray-900 space-y-1">
                  <div className="w-36 h-5 bg-gray-300 animate-pulse rounded-sm "></div>
                  <div className="w-36 h-5 bg-gray-300 animate-pulse rounded-sm "></div>
                  <div className="w-36 h-5 bg-gray-300 animate-pulse rounded-sm "></div>
                  <div className="w-36 h-5 bg-gray-300 animate-pulse rounded-sm "></div>
                  <div className="w-36 h-5 bg-gray-300 animate-pulse rounded-sm "></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
