import React from 'react';
import { TableHeader } from '../TableHeader';

export const LoadingTable = ({
  h,
  endCell,
  title,
  titleClassName,
  className,
  pagination,
  id
}) => {
  return (
    <div className={className || `flex flex-col max-w-5xl mx-auto`}>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg pb-4">
            {title && (
              <div className="md:flex  md:items-center md:justify-between max-w-6xl mx-auto mt-6 mb-2 px-4">
                <div className="flex-1 min-w-0">
                  <h3
                    className={
                      titleClassName ||
                      'text-xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate'
                    }
                  >
                    {title}
                  </h3>
                </div>
              </div>
            )}
            <table className="min-w-full divide-y divide-gray-200">
              <TableHeader
                h={Array.from(h.keys())}
                endCell={endCell}
                pagination={pagination}
              />
              <tbody className="bg-white divide-y divide-gray-200">
                {Array(10)
                  .fill(0)
                  .map((_, i) => (
                    <tr key={`loading-row-${id}-${i}`}>
                      {Array(h.size)
                        .fill(0)
                        .map((_, j) => {
                          return (
                            <td
                              key={`${id}-row-${i}-col-${j}`}
                              className="px-6 py-4 whitespace-normal"
                            >
                              <div className="w-full h-6 bg-gray-300 animate-pulse rounded-sm " />
                            </td>
                          );
                        })}
                      {endCell && (
                        <td className="px-6 py-4 text-sm font-medium text-gray-900">
                          <div className="w-20 h-6 bg-gray-300 animate-pulse rounded-sm "></div>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
