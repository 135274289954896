import { TableHeader } from './TableHeader';
import { TableBody } from './TableBody';
import { LoadingTable } from './loading/LoadingTable';
import { PaginationButtons } from './PaginationButtons';
import { FilterDropdown } from './FilterDropdown';

/**
 * @param Props
 * data - reactQuery data
 * h - Map of header names to keys
 * endLink - Link to add to the end of each row (<id> will be replaced with the id of the row)
 * boolComponents - Object of header label to components to display if the value is true
 * title - optional
 * titleClassName - optional
 * className - optional
 * pagination - required fields: isFetching, page, handleNextPage, handlePreviousPage
 * extraColumn - add extra column e.g. pagination but not end link
 */
export const CustomTable = ({
  cellComponents = {},
  data = [],
  h,
  maps = {},
  endLink,
  boolComponents = {},
  title,
  titleClassName,
  className,
  filterComponent,
  pagination = null,
  extraColumn = 0,
  isLoading = false,
  ...rest
}) => {
  if (isLoading || pagination?.isFetching) {
    return (
      <LoadingTable
        pagination={pagination}
        className={className}
        titleClassName={titleClassName}
        title={title}
        endCell={endLink || !!extraColumn}
        id={rest.id}
        h={h}
      />
    );
  }

  return (
    <div className={className || `flex flex-col max-w-5xl mx-auto`} {...rest}>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg pb-4">
            {title && (
              <div className="md:flex  md:items-center md:justify-between max-w-6xl mx-auto mt-6 mb-2 px-4">
                <div className="flex-1 min-w-0">
                  <h3
                    className={
                      titleClassName ||
                      'text-xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate'
                    }
                  >
                    {title}
                  </h3>
                </div>
              </div>
            )}
            <div className={'flex w-full justify-end'}>
              <FilterDropdown filterComponent={filterComponent} />
              <PaginationButtons pagination={pagination} />
            </div>
            {data?.length > 0 ? (
              <div>
                <table className="min-w-full divide-y divide-gray-200 w-full">
                  <TableHeader
                    h={Array.from(h.keys())}
                    endCell={endLink}
                    pagination={pagination}
                  />
                  <TableBody
                    cellComponents={cellComponents}
                    keyPrefix={rest.id}
                    data={data}
                    maps={maps}
                    h={h}
                    endLink={endLink}
                    boolComponents={boolComponents}
                    extraColumn={extraColumn}
                  />
                </table>
              </div>
            ) : (
              <div className="md:flex  flex-col md:items-center md:justify-between max-w-6xl mx-auto px-4">
                <table className="min-w-full divide-y divide-gray-200">
                  <TableHeader
                    disableNextPage={true}
                    h={Array.from(h.keys())}
                    endCell={endLink}
                    pagination={pagination}
                  />
                </table>
                <div className="flex-1 min-w-0">No results found.</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
