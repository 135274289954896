import React, { useState, useEffect, useRef } from 'react';

export const FilterDropdown = ({ filterComponent }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const filterRef = useRef(null);

  const handleFilterClick = (e) => {
    e.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (!isDropdownOpen && event.target.closest('.dropdown-content')) return;
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  if (!filterComponent) return null;

  return (
    <div className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
      <button
        className="ml-2 p-1 text-gray-500 hover:text-gray-700"
        onClick={handleFilterClick}
        ref={filterRef}
      >
        <div className={'flex items-center'}>
          <span className={'pr-2'}>
            Filters
            {` (${filterComponent.numFiltersApplied})`}
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
            />
          </svg>
        </div>
      </button>

      {isDropdownOpen && (
        <div
          className="absolute"
          style={{
            left: filterRef.current.offsetLeft + filterRef.current.offsetWidth,
            zIndex: 9999
          }}
        >
          <div className="absolute right-0 w-48 mt-2 py-2 bg-white rounded-lg shadow-xl dropdown-content">
            <div className="p-2">
              {React.createElement(filterComponent.component, {
                ...filterComponent.props,
                ref: dropdownRef
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
